<template>
  <section class="w-full video-container relative">
    <video
      id="home-video"
      class="w-full h-auto"
      autoplay
      loop
      muted
      preload="auto"
      poster="/img/transparent.png"
      playsinline
    >
      <source id="home-video-webm" :src="videoUrls.webm" type='video/webm; codecs="vp8"' />
      <source id="home-video-mp4" :src="videoUrls.mp4" type="video/mp4" />

      <h1 v-text="$t('home.videoText1')" />
      <h2 v-text="$t('home.videoText2')" />
      <h3 v-text="$t('home.videoText3')" />
    </video>
    <div class="absolute w-full inset-x-0 bottom-4 flex justify-center">
      <!-- <router-link
        class="main-button large custom"
        type="button"
        tag="button"
        v-text="$t('common.shop')"
        to="/shop"
      /> -->
      <!-- <router-link
        class="main-button large custom"
        type="button"
        tag="button"
        v-text="$t('common.onSale')"
        to="/shop?toggle=onSale"
      /> -->
      <!-- <router-link
        class="main-button large custom"
        type="button"
        tag="button"
        v-text="$t('common.shop')"
        to="/product/X-PLA/X-PLA%20-%201.75mm%20-%20750g%20-%20Power%20Tool%20Light%20Green"
      /> -->
      <!-- <router-link
        class="main-button large custom"
        type="button"
        tag="button"
        v-text="$t('meta.knowledge.title')"
        to="/knowledge"
      /> -->
      <router-link
        class="main-button large custom"
        type="button"
        tag="button"
        v-text="$t('common.showMore')"
        to="/knowledge/articles/3D-printed_using_recycled_fishing_nets"
      />
    </div>
  </section>
</template>
<script>
export default {
  name: "HomeVideo",
  data() {
    return {
      videoSize: "small",
      // videoPath: {
      //   small: {
      //     webm: `${process.env.BASE_URL}videos/DEFAULT/Banner_220211_Mobil.webm`,
      //     mp4: `${process.env.BASE_URL}videos/DEFAULT/Banner_220211_Mobil.mp4`,
      //   },
      //   large: {
      //     webm: `${process.env.BASE_URL}videos/DEFAULT/Banner_220211_Desktop.webm`,
      //     mp4: `${process.env.BASE_URL}videos/DEFAULT/Banner_220211_Desktop.mp4`,
      //   },
      // },
      videoPath: {
        small: {
          webm: `${process.env.BASE_URL}videos/Haxkraft_Banner_Mobil.webm`,
          mp4: `${process.env.BASE_URL}videos/Haxkraft_Banner_Mobil.mp4`,
        },
        large: {
          webm: `${process.env.BASE_URL}videos/Haxkraft_Banner_Desktop.webm`,
          mp4: `${process.env.BASE_URL}videos/Haxkraft_Banner_Desktop.mp4`,
        },
      },
    };
  },
  computed: {
    videoUrls() {
      return {
        webm: this.videoPath[this.videoSize].webm,
        mp4: this.videoPath[this.videoSize].mp4,
      };
    },
  },
  methods: {
    initVideo() {
      const w = window.matchMedia("(max-width: 639px)");
      if (!w.matches) {
        this.videoSize = "large";
      }
    },
    resize() {
      const w = window.matchMedia("(max-width: 639px)");
      const video = document.getElementById("home-video");
      const newSize = w.matches ? "small" : "large";

      if (newSize !== this.videoSize) {
        video.pause();
        this.videoSize = newSize;
        video.load();
        video.play();
      }
    },
  },
  created() {
    this.initVideo();
  },
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  jsonld() {
    return {
      "@context": "https://schema.org/",
      "@type": "VideoObject",
      name: "add:north - 3D filaments",
      description: `${this.$t("home.videoText1")}. ${this.$t("home.videoText2")}. ${this.$t(
        "home.videoText3"
      )}`,
      thumbnailUrl: [
        "https://img.youtube.com/vi/tfaUR72Ot-k/0.jpg",
        "https://img.youtube.com/vi/tfaUR72Ot-k/1.jpg",
        "https://img.youtube.com/vi/tfaUR72Ot-k/2.jpg",
        "https://img.youtube.com/vi/tfaUR72Ot-k/3.jpg",
      ],
      contentUrl: `https://addnorth.${process.env.VUE_APP_DOMAIN}/videos/addnorth_large.webm`,
      uploadDate: new Date().toISOString(),
    };
  },
};
</script>
<style scoped lang="scss">
.video-container {
  height: 100vw;
}
@media (min-width: 640px) {
  .video-container {
    height: 26vw;
  }
}
video source {
  @apply w-full;
  @apply h-auto;
}
video[poster] {
  @apply w-full;
  @apply h-full;
}
</style>
